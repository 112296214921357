<template>
  <div class="mt-5">
    <b-row>
      <b-col sm="4">
        <b-form-group label-for="trades-option">
          <template v-slot:label>
            <span class="required-field">Trade role you are applying for</span>
          </template>
          <b-form-select
            v-model="selectedTrade"
            name="trades-option"
            :state="tradeState"
            required
          >
            <b-form-select-option
              v-for="trd in trades"
              :key="trd.trade_id"
              :value="trd.trade_id"
            >
              {{ trd.trade }}
            </b-form-select-option>
          </b-form-select>
          <b-form-invalid-feedback :state="tradeState">
            Select a trade
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col
        sm="2"
        class="pr-0 mr-1"
      >
        <b-form-group label-for="CSCS-card-radio">
          <template v-slot:label>
            <span class="required-field">Do you have a CSCS card?</span>
          </template>
          <b-form-radio-group
            v-model="cscsCard"
            :options="cscsCardOptions"
            name="CSCS-card-radio"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
            :state="cscsCardState"
            required
            class="mt-1"
          />
          <b-form-invalid-feedback
            :state="cscsCardState"
            class="mt-1"
          >
            Select Yes or No
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col sm="3">
        <b-form-group label-for="start-date">
          <template v-slot:label>
            <span class="required-field">What date can you start work?</span>
          </template>
          <b-form-datepicker
            id="start-date"
            v-model="startDate"
            selected-variant="primary"
            show-decade-nav
            reset-button
            close-button
            locale="en-GB"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
            :state="startDateState"
            required
            placeholder=""
          />
          <b-form-invalid-feedback :state="startDateState !== ''">
            Enter a start date
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BFormGroup, BRow, BCol, BFormSelect, BFormSelectOption, BFormInvalidFeedback, BFormDatepicker, BFormRadioGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import swal from 'sweetalert2'

export default {
  name: 'AppSubmissionSection',
  components: {
    BFormGroup,
    BRow,
    BCol,
    BFormSelect,
    BFormSelectOption,
    BFormInvalidFeedback,
    BFormDatepicker,
    BFormRadioGroup,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      formInvalid: false,
      invalidEmployerCheck: false,
      invalidBankAuth: false,
      invalidTermsAuth: false,
      cscsCardOptions: [
        { item: true, name: 'Yes' },
        { item: false, name: 'No' },
      ],
    }
  },
  computed: {
    ...mapGetters({ applicationValidation: 'applicants/getApplicationValidation' }),
    ...mapGetters({ opInfo: 'applicants/getCurrentApplication' }),
    ...mapGetters({ trades: 'app/getTrades' }),
    tradeName: {
      get() {
        if (this.opInfo.operativedetails[0] !== undefined) {
          return this.opInfo.operativedetails[0].tradename
        }
        return ''
      },
      set(value) {
        this.$store.commit('applicants/setApplicationTradeName', value)
      },
    },
    tradeState() {
      if (this.applicationValidation == null) return null

      if (this.selectedTrade === null || this.selectedTrade === undefined || this.selectedTrade < 0) {
        this.$store.commit('applicants/failApplicationValidation')
        return false
      }
      return true
    },
    selectedTrade: {
      get() {
        if (this.opInfo.trade_id === undefined) return -1
        return this.opInfo.trade_id
      },
      set(value) {
        this.$store.commit('applicants/setApplicationTradeId', value)
      },
    },
    cscsCardState() {
      if (this.applicationValidation == null) return null

      if (this.cscsCard === null || this.cscsCard === undefined) {
        this.$store.commit('applicants/failApplicationValidation')
        return false
      }

      return true
    },
    cscsCard: {
      get() {
        return this.opInfo.operativedetails[0].cscscard
      },
      set(value) {
        this.$store.commit('applicants/setApplicationCSCS', value)
      },
    },
    startDateState() {
      if (this.applicationValidation == null) return null

      if (this.startDate === null || this.startDate === undefined || this.startDate.length === 0) {
        this.$store.commit('applicants/failApplicationValidation')
        return false
      }
      return true
    },
    startDate: {
      get() {
        // New operative setup, so this will enforce a DOB
        if (this.opInfo.startdate === 'new') {
          return ''
        }
        // Existing operatives will have null if no date set, to prevent it being compulsory setting blank date
        if (this.opInfo.startdate === null) {
          return '    -  -  '
        }
        return this.opInfo.operativedetails[0].availability
      },
      set(value) {
        this.$store.commit('applicants/setApplicationAvailability', value)
      },
    },
    bankAuthCheck: {
      get() {
        return this.opInfo.bankinfo_auth
      },
      set(value) {
        this.$store.commit('applicants/setApplicationBankInfoAuth', value)
      },
    },
    termsCheck: {
      get() {
        return this.opInfo.terms_auth
      },
      set(value) {
        this.$store.commit('applicants/setApplicationTermsAuth', value)
      },
    },
  },
  created() {
    this.operativeDetails = { ...this.opInfo }
  },
  methods: {
    validateCaptcha(success) {
      this.captchaValidated = success
    },
    // submit(response) {
    // },
    showMessage(success, iconType, titleText, messageText) {
      swal.fire({
        title: titleText,
        text: messageText,
        icon: iconType,
        showConfirmButton: false,
        showCancelButton: success === false,
        showCloseButton: false,
        customClass: {
          cancelButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    },

    checksPassed() {
      if (this.bankAuthCheck === false) {
        this.invalidBankAuth = true
      }

      if (this.termsCheck === false) {
        this.invalidTermsAuth = true
      }

      this.$store.commit('app/startApplicationValidation')
      if (this.invalidBankAuth || this.invalidTermsAuth || this.invalidEmployerCheck || !this.applicationValidation) {
        this.showMessage(false, 'error', 'Error', 'To apply all required fields must be filled')
        return false
      }

      return true
    },
  },
}
</script>

<style scoped>
.agreements {
  margin-top: 0.3rem;
  display: flex;
}
</style>
